import './form_switch_component.css'

export default () => ({
  init() {
    const errorWrapper = this.$root.querySelector('.field-with-errors')

    if (errorWrapper) {
      this.$root.querySelector('input[type=checkbox]').addEventListener(
        'change',
        () => {
          errorWrapper.replaceWith(errorWrapper.firstChild)
        },
        false,
      )
    }
  },
})
